import React from "react";
import {Route, Routes} from "react-router-dom";
import {Wrapper} from "./components/Wrapper";
import {Places} from "./pages/Places/Places";
import {PlaceDrafts} from "./pages/PlaceDrafts/PlaceDrafts";
import {PlaceView} from "./pages/PlaceView/PlaceView";
import {DraftEdit} from "./pages/DraftEdit/DraftEdit";
import {PlaceEdit} from "./pages/PlaceEdit/PlaceEdit";
import Login from "./pages/Login/Login";

const App = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />}/>
            <Route path="/" element={<Wrapper />}>
                <Route path="places" index element={<Places />} />
                <Route path="place-drafts" index element={<PlaceDrafts />} />

                <Route path="place/:placeId" element={<PlaceView />}/>
                <Route path="place-edit/:placeId" element={<PlaceEdit />}/>
                <Route path="draft-edit/:draftId" element={<DraftEdit />}/>
            </Route>
        </Routes>
    );
};

export default App;