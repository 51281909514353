import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, message, Pagination, Row, Table, TableProps } from "antd";
import { PAApi } from "../../service/api";
import { PlaceDraft } from "../../service/pa";
import { AxiosError } from "axios";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

interface PlaceDraftTableRow {
    Id: number;
    Title: string;
    Address: string | null | undefined;
    PlaceId: number | undefined;
}

const INITIAL_PAGE_SIZE = 10;

export const PlaceDrafts: React.FC = () => {
    const navigateTo = useNavigate();
    const [offset, setOffset] = useState(0);
    const [q, setQ] = useState("");
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState<PlaceDraftTableRow[]>([]);
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);

    const createDraft = () => {
        PAApi()
            .placeDraft.placeDraftCreate({})
            .then(
                (draft: PlaceDraft) => {
                    navigateTo(`/draft-edit/${draft.id}`);
                },
                (error: AxiosError) => {
                    console.error(error);
                    message.error("Failed to create draft.");
                }
            );
    };

    const deleteDraft = (draftId: number) => {
        PAApi()
            .placeDraft.placeDraftDelete(draftId)
            .then(
                () => {
                    reloadTable();
                },
                (error: AxiosError) => {
                    console.error(error);
                    message.error("Failed to delete draft.");
                }
            );
    };

    const reloadTable = () => {
        const req = { limit: pageSize, offset: offset, q: q || undefined };

        PAApi().placeDraft.placeDraftList(req).then(
            (response: { total: number; items: PlaceDraft[] }) => {
                setTotal(response.total);
                setRows(response.items.map((p: PlaceDraft) => ({
                    Id: p.id,
                    Title: p.place.title,
                    Address: p.place.address,
                    PlaceId: p.place.id,
                })));
            },
            (error: AxiosError) => {
                console.error("Draft fetching failed:", error);
                message.error("Draft fetching failed");
            },
        );
    };

    useEffect(() => {
        reloadTable();
    }, []);

    useEffect(() => {
        reloadTable();
    }, [offset, pageSize, q]);

    const columns: TableProps<PlaceDraftTableRow>['columns'] = [
        {
            title: 'Id',
            dataIndex: 'Id',
            key: 'Id',
            render: (id) => <b>{id}</b>,
        },
        {
            title: 'Title',
            dataIndex: 'Title',
            key: 'Title',
        },
        {
            title: 'Address',
            dataIndex: 'Address',
            key: 'Address',
        },
        {
            title: 'PlaceId',
            dataIndex: 'PlaceId',
            key: 'PlaceId',
        },
        {
            title: 'Actions',
            dataIndex: '_',
            key: 'Actions',
            render: (_, row: PlaceDraftTableRow) => (
                <Row>
                    <Col xs={24}>
                        <Button type="primary" icon={<EditOutlined />} size="middle" onClick={() => navigateTo("/draft-edit/" + row.Id)} />
                        &nbsp;
                        <Button type="primary" icon={<DeleteOutlined />} size="middle" onClick={() => deleteDraft(Number(row.Id))} />
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <>
            <Row>
                <Col xs={12}>
                    <Form>
                        <Form.Item label="Title">
                            <Input
                                onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                    setQ((e.target as HTMLInputElement).value);
                                }}
                                width="50%"
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={8}></Col>
                <Col xs={4}>
                    <Button type="primary" onClick={() => createDraft()}>Create New Draft</Button>
                </Col>
            </Row>
            <Table<PlaceDraftTableRow> columns={columns} dataSource={rows} rowKey={(row: PlaceDraftTableRow) => row.Id} pagination={false} />
            <Pagination
                total={total}
                current={offset / pageSize + 1}
                pageSize={pageSize}
                onChange={(page: number, pageSize?: number) => {
                    setOffset((page - 1) * (pageSize || INITIAL_PAGE_SIZE));
                    if (pageSize) setPageSize(pageSize);
                }}
            />
        </>
    );
};