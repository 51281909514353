import { Navigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Place, PlaceDraft } from "../../service/pa";
import { PAApi } from "../../service/api";
import { AxiosError } from "axios";
import { Spin, message } from "antd";

export const PlaceEdit: React.FC = () => {
    const { placeId } = useParams<{ placeId: string }>();
    const [draftId, setDraftId] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const isDraftCreated = useRef(false);

    const fetchPlaceDetails = useCallback(() => {
        if (!placeId) return;

        setLoading(true);
        PAApi()
            .place.placeDetail(Number(placeId))
            .then(
                (pl: Place) => {
                    if (pl.draftId) {
                        setDraftId(pl.draftId);
                        setLoading(false);
                    } else if (!isDraftCreated.current) {
                        createDraft(pl.id);
                    }
                },
                (error: AxiosError) => {
                    console.error(error);
                    message.error("Failed to fetch place details.");
                    setLoading(false);
                }
            );
    }, [placeId]);

    const createDraft = useCallback((placeId: number) => {
        isDraftCreated.current = true;
        PAApi()
            .placeDraft.placeDraftCreate({ placeId })
            .then(
                (draft: PlaceDraft) => {
                    setDraftId(draft.id);
                },
                (error: AxiosError) => {
                    console.error(error);
                    message.error("Failed to create draft.");
                }
            )
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchPlaceDetails();
    }, [fetchPlaceDetails]);

    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    if (draftId) {
        return <Navigate to={`/draft-edit/${draftId}`} replace />;
    }

    return null;
};