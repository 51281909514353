import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PAApi } from "../../service/api";
import { PlaceImage, PlaceDraft, PlaceDraftPatch } from "../../service/pa";
import { AxiosError } from "axios";
import { Button, Col, Row, Spin, Card, Image, Form, Input, InputNumber, DatePicker, Upload, message, Select } from "antd";
import { SaveOutlined, UploadOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import moment from 'moment';
import { RcFile, UploadChangeParam } from "antd/es/upload";

interface Cuisine {
    id: number;
    name: string;
}

interface Type {
    id: number;
    name: string;
}

export const DraftEdit: React.FC = () => {
    const { draftId } = useParams<{ draftId: string }>();
    const navigateTo = useNavigate();
    const [placeDraft, setPlaceDraft] = useState<PlaceDraft | null>(null);
    const [loading, setLoading] = useState(false);
    const [cuisinesOptions, setCuisinesOptions] = useState<Cuisine[]>([]);
    const [typesOptions, setTypesOptions] = useState<Type[]>([]);
    const [form] = Form.useForm();

    useEffect(() => {
        if (draftId) {
            PAApi()
                .placeDraft.placeDraftDetail(Number(draftId))
                .then(
                    (pd: PlaceDraft) => {
                        setPlaceDraft(pd);
                        form.setFieldsValue({
                            ...pd.place,
                            workingHours: pd.place.workingHours || [],
                            createdAt: pd.place.createdAt ? moment(pd.place.createdAt) : moment(),
                            updatedAt: pd.place.updatedAt ? moment(pd.place.updatedAt) : moment(),
                            cuisines: pd.place.cuisines?.map(cuisine => cuisine.id),
                            types: pd.place.types?.map(type => type.id)
                        });
                    },
                    (r: AxiosError) => console.log(r)
                );
        }

        PAApi()
            .placeDictionary.placeDictionaryDetail("cuisine")
            .then(
                dictionary => {
                    setCuisinesOptions(dictionary.items || []);
                },
                (r: AxiosError) => console.log(r)
            );

        PAApi()
            .placeDictionary.placeDictionaryDetail("type")
            .then(
                dictionary => {
                    setTypesOptions(dictionary.items || []);
                },
                (r: AxiosError) => console.log(r)
            );
    }, [draftId, form]);

    const handleSave = (): Promise<void> => {
        return form.validateFields().then((values) => {
            setLoading(true);

            const selectedCuisines = values.cuisines || [];
            const selectedTypes = values.types || [];
            const updatedPlace: PlaceDraftPatch = {
                ...values,
                createdAt: values.createdAt ? values.createdAt.toISOString() : moment().toISOString(),
                updatedAt: moment().toISOString(),
                cuisines: selectedCuisines,
                types: selectedTypes
            };

            return PAApi().placeDraft.placeDraftPartialUpdate(Number(draftId), updatedPlace)
                .then(() => {
                    setLoading(false);
                    message.success("Draft updated successfully");
                })
                .catch((error: AxiosError) => {
                    setLoading(false);
                    console.error("Draft update failed:", error);
                    message.error("Draft update failed");
                    throw error;
                });
        }).catch((errorInfo) => {
            console.error("Validation failed:", errorInfo);
            message.error("Validation failed. Please check the fields and try again.");
            return Promise.reject(errorInfo);
        });
    };

    const handlePublish = () => {
        setLoading(true);
        PAApi().placeDraft.publishCreate(Number(draftId), {})
            .then(() => {
                setLoading(false);
                message.success("Draft published successfully");
                navigateTo("/places");
            })
            .catch((error: AxiosError) => {
                setLoading(false);
                console.error("Draft publish failed:", error);
                message.error("Draft publish failed");
            });
    };

    const handleSaveAndPublish = () => {
        handleSave()
            .then(() => {
                handlePublish();
            })
            .catch((error) => {
                console.error("Save and Publish failed:", error);
            });
    };

    const handleImageUpload = (file: RcFile, onSuccess: any, onError: any) => {
        setLoading(true);
        PAApi().placeDraft.imageCreate(Number(draftId), { file: file })
            .then((uploadedImage: PlaceImage) => {
                setPlaceDraft(prevPlaceDraft => ({
                    ...prevPlaceDraft!,
                    place: {
                        ...prevPlaceDraft!.place,
                        images: [...(prevPlaceDraft!.place.images || []), uploadedImage]
                    }
                }));
                setLoading(false);
                message.success(`${file.name} file uploaded successfully`);
                onSuccess();
            })
            .catch((error: AxiosError) => {
                setLoading(false);
                console.error("Image upload failed:", error);
                message.error(`${file.name} file upload failed.`);
                onError(error);
            });
    };

    const handleImageDelete = (imageId: number) => {
        setLoading(true);
        PAApi().placeDraft.imageDelete(Number(draftId), imageId)
            .then(() => {
                setPlaceDraft(prevPlaceDraft => ({
                    ...prevPlaceDraft!,
                    place: {
                        ...prevPlaceDraft!.place,
                        images: prevPlaceDraft!.place.images.filter(image => image.id !== imageId)
                    }
                }));
                setLoading(false);
                message.success(`Image deleted successfully`);
            })
            .catch((error: AxiosError) => {
                setLoading(false);
                console.error("Image deletion failed:", error);
                message.error(`Image deletion failed`);
            });
    };

    const handleLogoUpload = (file: RcFile, onSuccess: any, onError: any) => {
        setLoading(true);
        PAApi().placeDraft.logoUpdate(Number(draftId), { file: file })
            .then((logo: { url: string }) => {
                setPlaceDraft(prevPlaceDraft => ({
                    ...prevPlaceDraft!,
                    place: {
                        ...prevPlaceDraft!.place,
                        logoUrl: logo.url
                    }
                }));
                setLoading(false);
                message.success(`${file.name} file uploaded successfully as logo`);
                onSuccess();
            })
            .catch((error: AxiosError) => {
                setLoading(false);
                console.error("Logo upload failed:", error);
                message.error(`${file.name} file upload failed as logo.`);
                onError(error);
            });
    };

    const handleLogoDelete = () => {
        setPlaceDraft(prevPlaceDraft => ({
            ...prevPlaceDraft!,
            place: {
                ...prevPlaceDraft!.place,
                logoUrl: null
            }
        }));
        message.success("Logo deleted successfully");
    };

    const uploadProps = {
        name: 'file',
        customRequest: ({ file, onSuccess, onError }: any) => {
            handleImageUpload(file as RcFile, onSuccess, onError);
        },
        onChange: (info: UploadChangeParam) => {
            if (info.file.status === 'done') {
                message.success("Image uploaded successfully");
            } else if (info.file.status === 'error') {
                message.error("Image upload failed");
            }
        }
    };

    const uploadLogoProps = {
        name: 'file',
        customRequest: ({ file, onSuccess, onError }: any) => {
            handleLogoUpload(file as RcFile, onSuccess, onError);
        },
        onChange: (info: UploadChangeParam) => {
            if (info.file.status === 'done') {
                message.success("Logo uploaded successfully");
            } else if (info.file.status === 'error') {
                message.error("Logo upload failed");
            }
        }
    };

    if (!placeDraft) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <Row justify="center" style={{ padding: "20px" }}>
            {loading && (
                <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0,0,0,0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin size="large" />
                </div>
            )}
            <Col span={24}>
                <Card
                    title={placeDraft.place.title}
                    extra={
                        <div>
                            <Button type="primary" icon={<SaveOutlined />} onClick={handleSave} style={{ marginRight: 8 }}>
                                Save
                            </Button>
                            <Button type="primary" icon={<EditOutlined />} onClick={handleSaveAndPublish}>
                                Publish
                            </Button>
                        </div>
                    }
                >
                    <Form
                        form={form}
                        layout="horizontal"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                    >
                        {placeDraft.place.logoUrl && (
                            <Row justify="start" style={{ marginBottom: "20px" }}>
                                <Image src={placeDraft.place.logoUrl} alt={`${placeDraft.place.title} Logo`} width={200} />
                                <Button
                                    type="primary"
                                    icon={<DeleteOutlined />}
                                    danger
                                    size="small"
                                    style={{ marginLeft: '10px' }}
                                    onClick={handleLogoDelete}
                                />
                            </Row>
                        )}
                        <Form.Item label="Logo">
                            <Upload {...uploadLogoProps}>
                                <Button icon={<UploadOutlined />}>Upload Logo</Button>
                            </Upload>
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="title"
                                    label="Title"
                                    rules={[{ required: true, message: "Title is required" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="city"
                                    label="City"
                                    rules={[{ required: true, message: "City is required" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="address"
                                    label="Address">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="rating"
                                    label="Rating">
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="shortDescription"
                                    label="Short Description">
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Working Hours">
                                    <Form.List name="workingHours">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Row key={key} gutter={8} align="middle">
                                                        <Col span={22}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={name}
                                                                rules={[{ required: true, message: 'Missing working hour' }]}>
                                                                <Input placeholder="Working Hour" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={2}>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => remove(name)}
                                                                icon={<DeleteOutlined />}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        block
                                                        icon={<PlusOutlined />}>
                                                        Add Working Hour
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="phone"
                                    label="Phone">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="link"
                                    label="Link">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <Form.Item
                                    name="priceFrom"
                                    label="Price From"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="priceTo"
                                    label="Price To">
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                            <Col span={16} />
                        </Row>
                        <Row>
                            <Col span={4}>
                                <Form.Item
                                    name="lon"
                                    label="Longitude"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="lat"
                                    label="Latitude">
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                            <Col span={16} />
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="types"
                                    label="Types">
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select types"
                                        options={typesOptions.map(type => ({ value: type.id, label: type.name }))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="cuisines"
                                    label="Cuisines">
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select cuisines"
                                        options={cuisinesOptions.map(cuisine => ({ value: cuisine.id, label: cuisine.name }))}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Images">
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />}>Upload Image</Button>
                            </Upload>
                            <Row gutter={16}>
                                {placeDraft.place.images && placeDraft.place.images.map((image: PlaceImage) => (
                                    <Col key={image.id} style={{ position: 'relative' }}>
                                        <Image src={image.url} alt={image.path} width={100} height={100} />
                                        <Button
                                            type="primary"
                                            icon={<DeleteOutlined />}
                                            danger
                                            size="small"
                                            style={{ position: 'absolute', top: 5, right: 5 }}
                                            onClick={() => handleImageDelete(image.id)}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="externalId"
                                    label="External ID">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="createdAt"
                                    label="Created At"
                                    initialValue={moment()}
                                >
                                    <DatePicker showTime defaultValue={moment()} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="updatedAt"
                                    label="Updated At"
                                    initialValue={moment()}
                                >
                                    <DatePicker showTime defaultValue={moment()} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};