import React, { useState } from 'react';
import { PAApi } from "../../service/api";
import { message, Form, Input, Button, Card } from 'antd';
import { useNavigate } from "react-router-dom";

const BasicAuthPage: React.FC = () => {
    const navigateTo = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        const token = btoa(`${username}:${password}`);

        PAApi(token).place.placeList({ limit: 1, offset: 0 })
            .then(response => {
                message.success("Success!");
                localStorage.setItem("pa.auth", token);
                navigateTo("/places");
            })
            .catch(error => {
                console.error(error);
                message.error("Failed!");
            });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card title="Basic Auth Login" style={{ width: 300 }}>
                <Form
                    name="basic_auth"
                    initialValues={{ remember: true }}
                    onFinish={handleLogin}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default BasicAuthPage;