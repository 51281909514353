import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';

import {
    RestOutlined,
    FormOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';

import './Wrapper.scss';
import {basicAuth} from "../../service/auth";

const { Sider, Content } = Layout;

const b = block('wrapper');

export const Wrapper: React.FC = () => {
    let navigateTo = useNavigate()
    let location = useLocation();

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    if (!basicAuth()) {
        return <Navigate to="/login" />
    }

    let activeItems: string[] = [];
    if (location.pathname.includes("draft")) {
        activeItems.push("place-drafts");
    } else {
        activeItems.push("places")
    }

    return (
        <Layout className={b()}>
            <Sider trigger={null}>
                <div className={b("demo-logo-vertical")}>Place Admin</div>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={activeItems}
                    items={[
                        {
                            key: 'places',
                            icon: <RestOutlined/>,
                            label: 'Рестораны',
                            onClick: () => navigateTo("/places"),
                        },
                        {
                            key: 'place-drafts',
                            icon: <FormOutlined/>,
                            label: 'На редактировании',
                            onClick: () => navigateTo("/place-drafts")
                        }
                    ]}
                />
            </Sider>
            <Layout>
            <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Outlet/>
                </Content>
            </Layout>
        </Layout>
    );
};
