import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { PAApi } from "../../service/api";
import { Place, PlaceImage, PlaceDictionaryValue } from "../../service/pa";
import { AxiosError } from "axios";
import { Button, Col, Row, Spin, Card, Descriptions, Image, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";

export const PlaceView: React.FC = () => {
    const navigateTo = useNavigate()
    const { placeId } = useParams<{ placeId: string }>();
    const [place, setPlace] = useState<Place | null>(null);

    useEffect(() => {
        if (placeId) {
            PAApi()
                .place.placeDetail(Number(placeId))
                .then(
                    (pl: Place) => {
                        setPlace(pl);
                    },
                    (r: AxiosError) => console.log(r)
                );
        }
    }, [placeId]);

    if (!place) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Spin size="large" />
            </div>
        );
    }

    return (
        <Row justify="center" style={{ padding: "20px" }}>
            <Col span={24}>
                <Card
                    title={place.title}
                    extra={
                        <Button type="primary" icon={<EditOutlined />} onClick={() => navigateTo("/place-edit/"+placeId)}>
                            Edit
                        </Button>
                    }
                >
                    {place.logoUrl && (
                        <Row justify="center" style={{ marginBottom: "20px" }}>
                            <Image src={place.logoUrl} alt={`${place.title} Logo`} width={200} />
                        </Row>
                    )}
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label="Title">{place.title}</Descriptions.Item>
                        <Descriptions.Item label="City">{place.city}</Descriptions.Item>
                        <Descriptions.Item label="Address">{place.address}</Descriptions.Item>
                        <Descriptions.Item label="Rating">{place.rating ?? "N/A"}</Descriptions.Item>
                        <Descriptions.Item label="Description">{place.shortDescription ?? "N/A"}</Descriptions.Item>
                        <Descriptions.Item label="Working Hours">
                            {place.workingHours?.join(", ") ?? "N/A"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Phone">{place.phone ?? "N/A"}</Descriptions.Item>
                        {place.link && (
                            <Descriptions.Item label="Link">
                                <a href={place.link} target="_blank" rel="noopener noreferrer">
                                    Visit Place
                                </a>
                            </Descriptions.Item>
                        )}
                        <Descriptions.Item label="Prices">
                            {place.priceFrom} - {place.priceTo}
                        </Descriptions.Item>
                        <Descriptions.Item label="Types">
                            {place.types.map((type: PlaceDictionaryValue) => (
                                <Tag key={type.id}>{type.name}</Tag>
                            ))}
                        </Descriptions.Item>
                        <Descriptions.Item label="Cuisines">
                            {place.cuisines.map((cuisine: PlaceDictionaryValue) => (
                                <Tag key={cuisine.id}>{cuisine.name}</Tag>
                            ))}
                        </Descriptions.Item>
                        <Descriptions.Item label="Images">
                            <Row gutter={[8, 8]}>
                                {place.images.map((image: PlaceImage) => (
                                    <Col key={image.id}>
                                        <Image src={image.url} alt={image.path} width={100} height={100} />
                                    </Col>
                                ))}
                            </Row>
                        </Descriptions.Item>
                        {place.lon && place.lat && (
                            <Descriptions.Item label="Location">
                                {`Lon: ${place.lon}, Lat: ${place.lat}`}
                            </Descriptions.Item>
                        )}
                        {place.externalId && (
                            <Descriptions.Item label="External ID">{place.externalId}</Descriptions.Item>
                        )}
                        {place.createdAt && (
                            <Descriptions.Item label="Created At">{new Date(place.createdAt).toLocaleString()}</Descriptions.Item>
                        )}
                        {place.updatedAt && (
                            <Descriptions.Item label="Updated At">{new Date(place.updatedAt).toLocaleString()}</Descriptions.Item>
                        )}
                        {place.draftId && (
                            <Descriptions.Item label="Draft ID">{place.draftId}</Descriptions.Item>
                        )}
                    </Descriptions>
                </Card>
            </Col>
        </Row>
    );
};