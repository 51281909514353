import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, message, Pagination, Row, Table, TableProps } from "antd";
import { PAApi } from "../../service/api";
import { Place } from "../../service/pa";
import { AxiosError } from "axios";
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

interface PlaceTableRow {
    Id: number;
    Title: string;
    Address: string | null | undefined;
    DraftId: number | undefined;
}

const INITIAL_PAGE_SIZE = 10

export const Places: React.FC = () => {
    const navigateTo = useNavigate();
    const [offset, setOffset] = useState(0);
    const [q, setQ] = useState("");
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState<PlaceTableRow[]>([]);
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);

    const reloadTable = () => {
        const req = { limit: pageSize, offset: offset, q: q || undefined };

        PAApi().place.placeList(req).then(
            (response) => {
                setTotal(response.total);
                setRows(response.items.map((p: Place) => ({
                    Id: p.id,
                    Title: p.title,
                    Address: p.address,
                    DraftId: p.draftId,
                })));
            },
            (error: AxiosError) => {
                console.error("Places fetching failed:", error);
                message.error("Places fetching failed");
            },
        );
    };

    useEffect(() => {
        reloadTable();
    }, [offset, pageSize, q]);

    const columns: TableProps<PlaceTableRow>['columns'] = [
        {
            title: 'Id',
            dataIndex: 'Id',
            key: 'Id',
            render: (id) => <b>{id}</b>,
        },
        {
            title: 'Title',
            dataIndex: 'Title',
            key: 'Title',
        },
        {
            title: 'Address',
            dataIndex: 'Address',
            key: 'Address',
        },
        {
            title: 'DraftId',
            dataIndex: 'DraftId',
            key: 'DraftId',
        },
        {
            title: 'Actions',
            dataIndex: '_',
            key: 'Actions',
            render: (_, row: PlaceTableRow) => (
                <Row>
                    <Col xs={24}>
                        <Button
                            type="primary"
                            icon={<EyeOutlined />}
                            size="middle"
                            onClick={() => navigateTo("/place/" + row.Id)}
                        />
                        &nbsp;
                        {row.DraftId ? (
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                size="middle"
                                onClick={() => navigateTo("/draft-edit/" + row.DraftId)}
                            />
                        ) : (
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                size="middle"
                                onClick={() => navigateTo("/place-edit/" + row.Id)}
                            />
                        )}
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <>
            <Row>
                <Col xs={12}>
                    <Form>
                        <Form.Item label="Title">
                            <Input
                                onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                    setQ((e.target as HTMLInputElement).value);
                                }}
                                width="50%"
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Table<PlaceTableRow> columns={columns} dataSource={rows} rowKey={(row: PlaceTableRow) => row.Id} pagination={false} />
            <Pagination
                total={total}
                current={Math.ceil((offset + 1) / pageSize)}
                pageSize={pageSize}
                onChange={(page: number, pageSize?: number) => {
                    setOffset((page - 1) * (pageSize || INITIAL_PAGE_SIZE));
                    if (pageSize) setPageSize(pageSize);
                }}
            />
        </>
    );
};