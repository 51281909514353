import {Api} from "./pa";
import {basicAuth} from "./auth";

export const PAApi = (token?: string) => {
    let auth: string|null

    if (token) {
        auth = `Basic ${token}`
    } else {
        auth = `Basic ${basicAuth()}`
    }

    return new Api<any>({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {Authorization: auth},
    });
}